<template>
  <div>
      <div class="columns">
        <div class="column is-3 is-0-mobile"></div>
        <div class="column is-6">
          <div
              class="card is-shadowless is-bordered"
              style="border: 2px dashed rgba(0, 0, 0, 0.13); padding: 16px; margin-left: auto; margin-right: auto;"
          >
            <b-field label="Wiadomość powitalna">
              <b-input v-model="settings.welcome"></b-input>
            </b-field>
            <b-field label="Nazwa podmiotu">
              <b-input v-model="settings.owner"></b-input>
            </b-field>
            <b-field label="Adres podmiotu">
              <b-input v-model="settings.owner_address"></b-input>
            </b-field>
            <b-field label="Adres email powiadomień">
              <b-input v-model="settings.notification_email"></b-input>
            </b-field>
            <button class="button is-success" @click="save">Zapisz</button>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import HomeWrapper from "../components/HomeWrapper";
import pdf from 'vue-pdf'

export default {
  name: "Settings",
  components: {HomeWrapper, pdf},
  mounted() {
    this.load();
  },
  data() {
    return {
      settings: {},
    };
  },
  methods: {
    async load() {
      this.settings = await this.$api.loadSettings();
    },
    async save() {
      await this.$api.saveWelcomeMessage(this.settings.welcome);
      await this.$api.saveOwnerMessage(this.settings.owner);
      await this.$api.saveOwnerAddressMessage(this.settings.owner_address);
      await this.$api.saveNotificationEmail(this.settings.notification_email);
      this.$buefy.toast.open({
        duration: 1000,
        message: 'Zapisano!',
        position: 'is-top',
        type: 'is-success'
      })
    }
  }
}
</script>

<style scoped>

</style>
